import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql, Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import K from "../images/kaputtmacher-seal-animated.gif";

export const data = graphql`
  query {
    headerApologies: file(
      relativePath: { eq: "studio_session_donots_01.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 2880) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sessionsSetup: file(relativePath: { eq: "sessions_setup.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 2880) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default ({ data }) => (
  <Layout type="Sessions">
    <SEO
      keywords={[
        `music production`,
        `Musikproduktion`,
        `music producer`,
        `Musikproduzent`,
        `recording studio`,
        `Tonstudio`
      ]}
      title="Kaputtmacher Sessions"
    />
    <div>
      <div className="md:flex  c-topblock" id="top">
        <div
          className="
        md:w-1/2 px-4 sm:px-12 lg:px-14 py-10 md:py-16 pt-32 pb-16 flex items-center z-10
        "
        >
          <div>
            <h1 className="mb-2 text-6xl font-semibold leading-negative">
              Kaputtmacher Sessions
            </h1>

            <p className="text-xl mb-4">Boutique quality live sessions </p>

            <hr className="w-12 border-2 mb-4 border-black" />
            <p>
              <a
                href="https://youtube.com/c/kaputtmacher"
                className="greenline mr-4"
              >
                Watch Kaputtmacher Sessions
              </a>{" "}
              <a href="#apply-now" className="greenline">
                Apply now
              </a>
            </p>
          </div>
        </div>

        <BackgroundImage
          fluid={data.headerApologies.childImageSharp.fluid}
          className="md:w-1/2 h-screen md:h-screen bg-fixed bg-center bg-cover"
        ></BackgroundImage>
      </div>

      <div
        className="md:flex md:h-doublescreen h-screen relative"
        id="what-we-do"
      >
        <BackgroundImage
          fluid={data.sessionsSetup.childImageSharp.fluid}
          loading="eager"
          fadeIn="false"
          className="bg-fixed bg-center bg-cover md:h-doublescreen h-screen w-full md:flex flex-row-reverse"
        >
          <div
            className="
          
          bg-white md:h-screen md:w-1/2  px-4 sm:px-8 lg:px-16 py-10 sm:py-10 md:py-16  flex self-end items-center z-10"
          >
            <div className="max-w-sm">
              <h2 className="text-3xl font-semibold mb-2">
                About the Sessions
              </h2>
              <p className="mb-4">
                The Kaputtmacher Sessions celebrate raw live performance: No
                tricks, no overdubs, just the bands skills paired with great
                production. Show off your live chops, reach new listeners and
                impress bookers in an honest setting.
              </p>
              <p className="mb-4">
                Since 2014 we have produced{" "}
                <a
                  href="https://youtube.com/c/kaputtmacher"
                  className="greenline"
                >
                  over 60 sessions
                </a>{" "}
                Sessions. If you are an amazing live act, no matter the genre,
                we are definitely interested in working with you.
              </p>
              <p>
                <a
                  className="button button--heavy px-3 pt-2 pb-1"
                  href="#apply-now"
                >
                  Apply for a Session
                </a>
              </p>
            </div>
          </div>
        </BackgroundImage>
      </div>
    </div>

    <div class="bg-black text-white">
      <div
        id="apply-now"
        className="px-4 sm:px-8 lg:px-16 py-12 md:py-16 lg:py-24 md:w-2/3 flex items-center z-10 mx-auto text-center"
      >
        <div className="w-full">
          <h2 className="text-4xl leading-none font-semibold mb-3">
            Upcoming Shoots
          </h2>

          <p className="text-lg">
            There are no shoots anounced just now. You can always{" "}
            <a
              className="greenline-white"
              href="mail&#116;o&#58;hello&#64;&#107;a&#37;7&#48;utt&#109;a&#99;he%72&#46;&#37;63o&#109;?body=(Feel free to write in English or German)"
            >
              get in touch
            </a>{" "}
            and let us know you are intersted tho.
          </p>
        </div>
      </div>
    </div>
    {/* px-4 sm:px-8 lg:px-16 py-8 md:py-16 md:w-1/2 flex items-center z-10 */}
  </Layout>
);
